.CardPickerContainer {
  bottom: 0;
  padding: 10px 50px 10px 50px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.CardPicker {
  min-width: 100px;
  max-width: 100px;
  max-height: 140px;
  min-height: 140px;
  margin-top: 10px;
  cursor: pointer;
  background-color: var(--color-background-secondary);
}

.CardPicker:hover {
  box-shadow: 0 0px 12px 0 lightgrey;
}

.CardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 20px !important;
}

.CardContentTop {
  margin-top: -5px;
  text-align: left;
}

.CardContentMiddle {
  padding: 18px 0 18px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.CardContentBottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 25px;
  text-align: right;
}
