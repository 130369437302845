.RecentGamesCard {
  width: 450px;
  min-width: 120px;
  margin: 10px;
  margin-top: 40px;
  overflow: initial;
  background-color: var(--color-background);
  transition: 0.3s;
  border-radius: 4px;
}

.RecentGamesCardTitle {
  display: flex;
  text-align: center;
  border-radius: 16px;
  border: 2px solid var(--color-primary);
  background-color:  var(--color-background);
  margin: -20px auto 0;
  width: 88%;
  padding: 16px;
  height: 40px;
}

.MuiCardHeader-content {
  width: 100%;
}

.RecentGamesTableContainer {
  max-height: 250px;
}

.RecentGamesTableRow {
  cursor: pointer;
}

.RecentGamesCardContent {
  text-align: center;
}
