.AppBar {
  box-shadow: none;
  background-color:  var(--color-background);
  flex-grow: 1;
  white-space: nowrap;
}

.HeaderContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.HeaderLeftContainer {
  display: flex;
  cursor: pointer;
}

.HeaderIcon {
  color: var(--color-primary);
  margin-top: 4px;
  margin-right: 5px;
}
