.CreateGameTextField {
  padding-bottom: 30px;
}

.CreateGameButton {
  color: var(--color-background);
  width: 70%;
  border-radius: 40px;
}

.CreateGameCard {
  background: transparent;
  border: none !important;
  padding: 50px;
}

.CreateGameCardHeader {
  text-align: center;
}

.CreateGameCardContent {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.CreateGameCardAction {
  justify-content: center;
}
