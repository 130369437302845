.HomePageContainer {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SessionImage {
  margin-top: -10px;
  width: 600px;
  transform: perspective(900px) rotateY(-20deg);
}

.HomePageImage {
  width: 500px;
}
